import { MethodCode, ModelCode } from '../../@types/Permission';
import { findPermission } from '../../sections/@dashboard/Permissions/utils';
var useNotifsPermissions = function (user) {
    var hasPermissionListNotif = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.NOTIFICATION, MethodCode.LIST);
    var hasPermissionViewNotif = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.NOTIFICATION, MethodCode.VIEW);
    var hasPermissionEditNotif = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.NOTIFICATION, MethodCode.EDIT);
    return {
        hasPermissionListNotif: hasPermissionListNotif,
        hasPermissionViewNotif: hasPermissionViewNotif,
        hasPermissionEditNotif: hasPermissionEditNotif,
    };
};
export default useNotifsPermissions;
